<template>
  <article class="control">
    <label :for="textareaName" class="label" :class="{ active: isActive }">
      {{ placeholder }}
    </label>
    <label>
      <textarea
        :name="textareaName"
        class="textarea has-fixed-size"
        :class="{ 'is-danger': error }"
        @focus="setActive"
        @blur="setBlur"
        :value="value"
        @input="$emit('update', $event.target.value)"
      />
    </label>
  </article>
</template>

<script>
export default {
  name: 'TextArea',
  props: ['textareaName', 'placeholder', 'value', 'error'],
  model: {
    prop: 'value',
    event: 'update',
  },
  data() {
    return {
      isActive: false,
    };
  },
  methods: {
    setActive() {
      this.isActive = true;
    },
    setBlur() {
      this.isActive = this.$props.value.length !== 0;
    },
  },
};
</script>

<style scoped lang="scss">
textarea {
  &.textarea {
    background-color: $grey;
    border-radius: 0px;
    min-height: 200px;
    color: #fff;
    font-size: 0.8rem;

    &:hover,
    &:active,
    &:focus {
      border: 1px solid $primary;
    }
  }
}

label {
  &.label {
    z-index: 1;
    position: absolute;
    margin: 0.8rem 0 0 0.8rem;
    font-size: 0.75rem;
    font-weight: normal;
    color: $grey-light;
  }

  &.active {
    display: none;
  }
}
</style>
